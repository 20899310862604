import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import TableColumns from "../global/tableColumns";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";
import { Edit } from "@mui/icons-material";
import toast from "react-hot-toast";
import AgentPopUp from "./agentPopUp";
import AddAgentPopUp from "./addAgentPopUp";
import {
  agentPayment,
  createAgent,
  getAllAgents,
  updateAgent,
} from "../../utils/Apis";
import { formatDate } from "../../utils/dateTimeFromater";

const AgentTable = ({ recall, filter }) => {
  let pages;
  const { isLoading, user, setUser, setIsLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  // const [pages, setPages] = useState("");
  const [pageSize, setPageSize] = useState("20");
  const [showModal, setShowModal] = useState(false);
  // const [modalType, setModalType] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showAddAgent, setShowAddAgent] = useState("");
  const [userDetail, setUserDetail] = useState({
    beneficiaryId: "",
    amount: 0,
    type: "deposit",
    creator: {},
    currentBalance: 0,
  });

  const [newAgentDetail, setNewAgentDetail] = useState({
    level: "",
    username: "",
    password: "",
    confirmPassword: "",
    agentId: "",
  });

  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
    {
      id: 1,
      value: 500,
    },
  ];
  const [rowData, setRowData] = useState([
    {
      id: "",
      dateCreated: "",
      username: "",
      level: "",
      manager: "",
      balance: "",
    },
  ]);

  const getAllAgentData = async (page, pageSize) => {
    try {
      const config = {
        params: {
          page: page,
          pageSize: pageSize,
        },
        filter: filter,
      };
      setLoading(true);
      setIsLoading(true);
      const res = await getAllAgents(config);
      if (res.status === 200) {
        setRowData(res.data);
        setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAgentData(page, pageSize);
    // eslint-disable-next-line
  }, [pageSize, page, recall]);

  const actionCellRenderer = (params) => {
    return (
      <div className="users-action-buttons">
        <button
          onClick={
            () => {
              setUserDetail({
                beneficiaryId: params?.data.id,
                type: "deposit",
                creator: { ...params?.data?.creator },
                currentBalance: params?.data?.balance,
              });
              // setModalType("deposit");
              setShowModal(true);
            }
            // handleDeposit(params?.data)
          }
          className="deposit-button"
        >
          +
        </button>
        <button
          onClick={
            () => {
              setShowModal(true);
              setUserDetail({
                beneficiaryId: params?.data.id,
                type: "withdraw",
                creator: { ...params?.data?.creator },
                currentBalance: params?.data?.balance,
              });
            }
            //  handleWithdraw(params?.data)
          }
          className="withdraw-button"
        >
          -
        </button>
        <button
          onClick={
            () => {
              setShowAddAgent("edit");
              setNewAgentDetail({
                level: params?.data?.level,
                agentId: params?.data?.agentId,
                username: params?.data?.username,
              });
            }
            //  handleWithdraw(params?.data)
          }
          className="edit-agent-button"
        >
          <Edit fontSize="10" />
        </button>
      </div>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      flex: 1,
      minWidth: 150,
      sortable: true,
      cellStyle: { color: "#4caf50", textAlign: "center" },
    },
    {
      headerName: "Agent ID",
      field: "agentId",
      flex: 1,
      minWidth: 200,
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Date Created",
      field: "createdAt",
      flex: 1,
      minWidth: 200,
      // sortable: true,
      sort: "desc",
      cellStyle: { textAlign: "center" },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "User Name",
      field: "username",
      flex: 1,
      minWidth: 200,
      cellStyle: { color: "#4caf50", textAlign: "left" },
    },
    {
      headerName: "Level",
      field: "level",
      flex: 1,
      minWidth: 200,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Manager",
      field: "manager",
      flex: 1,
      minWidth: 200,
      sortable: true,
      valueFormatter: (p) => p?.data?.creator?.username,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Balance",
      field: "balance",
      flex: 1,
      minWidth: 200,
      valueFormatter: (p) => parseFloat(p?.value).toFixed(2) || "-",
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Action",
      field: "actionButton",
      flex: 1,
      minWidth: 200,
      cellRenderer: (item) => actionCellRenderer(item),
    },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );
  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );
    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  const handleDepositWithdraw = async (e) => {
    try {
      e.preventDefault();
      if (
        userDetail?.amount <= userDetail?.creator?.balance ||
        userDetail?.creator?.level === "master" ||
        userDetail?.type === "withdraw"
      ) {
        if (
          userDetail?.beneficiaryId &&
          userDetail?.type &&
          userDetail?.amount
        ) {
          setPaymentLoading(true);
          setIsLoading(true);

          const res = await agentPayment({
            beneficiaryId: userDetail?.beneficiaryId,
            amount: parseInt(userDetail?.amount),
            type: userDetail?.type,
          });
          if (res.status === 200) {
            if (userDetail.type === "deposit") {
              setUser({ ...user, balance: user.balance - userDetail.amount });
            } else if (userDetail.type === "withdraw") {
              setUser({
                ...user,
                balance: parseInt(user.balance) + parseInt(userDetail.amount),
              });
            }
            setIsLoading(false);
            setPaymentLoading(false);
            getAllAgentData(page, pageSize);
            setShowModal(false);
            toast.success("Operation successful!");
          } else {
            setIsLoading(false);
            setPaymentLoading(false);
            toast.error(res.message);
          }
        } else {
          setPaymentLoading(false);
          return toast.error("Please Enter the amount or please try again!");
        }
      } else {
        setPaymentLoading(false);
        return toast.error("Please Enter a valid amount");
      }
    } catch (err) {
      setPaymentLoading(false);
      setShowModal(false);
      return toast.error("Something went wrong!");
    }
  };

  const handleNewAgent = async (values) => {
    try {
      setLoading(true);
      setIsLoading(true);
      const value = values.agentId.toUpperCase();
      const payload =
        showAddAgent === "edit"
          ? (() => {
              const tempValues = { ...values };
              delete tempValues.level;
              delete tempValues.confirmPassword;
              return tempValues;
            })()
          : values;
      const res =
        showAddAgent === "edit"
          ? await updateAgent({ ...payload, agentId: value })
          : await createAgent({ ...payload, agentId: value });
      if (res.status === 201 || 200) {
        toast.success(
          `${
            showAddAgent.charAt(0).toUpperCase() + showAddAgent.slice(1)
          } Agent Successfully`
        );
        setLoading(false);
        setIsLoading(false);
        setShowAddAgent("");
        getAllAgentData(page, pageSize);
      } else {
        toast.error(res.message);
        setLoading(false);
        setIsLoading(false);
      }
    } catch (err) {
      return toast.error("Something went wrong!");
    }
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Agents</h6>
        <div className="right-area">
          <button
            className="add-agent-button"
            onClick={() => {
              setShowAddAgent("add");
              setNewAgentDetail({
                level: "",
                username: "",
                agentId: "",
              });
            }}
          >
            Add Agent
          </button>
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : "20");
            }}
          />
          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            loading={loading}
            columnDefs={columnDefs}
            rowData={rowData}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
      <AgentPopUp
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleFunction={handleDepositWithdraw}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        paymentLoading={paymentLoading}
        // modalType={modalType}
        // setModalType={setModalType}
      />

      <AddAgentPopUp
        loading={loading}
        show={showAddAgent}
        handleClose={() => setShowAddAgent("")}
        handleNewAgent={handleNewAgent}
        setNewAgentDetail={setNewAgentDetail}
        agentDetails={newAgentDetail}
      />
    </div>
  );
};

export default AgentTable;
