import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import TableColumns from "../global/tableColumns";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import {
  createUser,
  getPlayerData,
  UpdateUser,
  // makeManualDepositWithdraw,
  userPayment,
} from "../../utils/Apis";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";
// import DepositPopup from "./deposit";
import WithdrawPopup from "./withdraw";
import toast from "react-hot-toast";
import AddUsersPopUp from "./AddUsersPopup";
import { Edit } from "@mui/icons-material";
import { formatDate } from "../../utils/dateTimeFromater";

const UserTable = ({ recall, filter }) => {
  const { isLoading, user, setUser } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [addUsers, setAddUsers] = useState("");
  const [page, setPage] = useState(1);
  const [pages] = useState("");
  const [pageSize, setPageSize] = useState("20");
  const [withdrawShow, setWithdrawShow] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedUserDetails, setSelectedUserDetails] = useState();
  const [userDetail, setUserDetail] = useState({
    userId: "",
    amount: 0,
    type: "deposit",
    currentAmount: 0,
  });

  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
    {
      id: 1,
      value: 500,
    },
  ];

  const getAllPlayerData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        };

        setLoading(true);
        const res = await getPlayerData(config);
        setLoading(false);
        if (res?.data) {
          // const sortedData = res?.data?.sort(
          //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          // );
          // console.log(res?.data, sortedData);
          setRowData(res?.data);
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPlayerData(page, pageSize);
    // eslint-disable-next-line
  }, [pageSize, page, recall]);

  // const [showDeposit, setShowDeposit] = useState(false);

  const actionCellRenderer = (params) => {
    return (
      <div className="users-action-buttons">
        <button
          onClick={() => {
            setUserDetail({
              userId: params?.data?.id,
              amount: params?.data?.amount,
              type: "deposit",
              currentAmount: params?.data?.balance,
            });
            setWithdrawShow(!withdrawShow);
          }}
          className="deposit-button"
        >
          +
        </button>
        <button
          onClick={() => {
            setUserDetail({
              userId: params?.data?.id,
              amount: params?.data?.amount,
              type: "withdraw",
              currentAmount: params?.data?.balance,
            });
            setWithdrawShow(!withdrawShow);
          }}
          className="withdraw-button"
        >
          -
        </button>
        <button
          onClick={
            () => {
              setAddUsers("edit");
              console.log(params?.data);
              setSelectedUserDetails(params?.data);
            }
            //  handleWithdraw(params?.data)
          }
          className="edit-agent-button"
        >
          <Edit fontSize="10" />
        </button>
      </div>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    // {
    //   headerName: "Brand",
    //   field: "brand",
    //   minWidth: 120,
    //   flex: 1,
    //   cellStyle: { color: "grey", left: "15px" },
    // },
    {
      headerName: "User ID",
      field: "id",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Date Created",
      field: "createdAt",
      minWidth: 200,
      flex: 1,
      cellRenderer: (params) => formatDate(params.value) || "-",
    },
    {
      headerName: "User",
      field: "userName",
      minWidth: 200,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    // {
    //   headerName: "Agent ID",
    //   field: "agentId",
    //   minWidth: 150,
    //   flex: 1,
    //   cellRenderer: (params) => params.value || "-",
    // },
    // {
    //   headerName: "Role",
    //   field: "role",
    //   minWidth: 150,
    //   flex: 1,
    //   cellRenderer: (params) => params.value || "-",
    // },
    {
      headerName: "Store",
      field: "creator",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      cellRenderer: () => user?.username,
    },
    {
      headerName: "Amount",
      field: "balance",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => parseFloat(params?.value).toFixed(2) || "-",
    },
    {
      headerName: "First Name",
      field: "firstName",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Last Name",
      field: "lastName",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    // {
    //   headerName: "Email",
    //   field: "email",
    //   minWidth: 280,
    //   flex: 1,
    //   cellRenderer: (params) => params.value || "-",
    // },
    {
      headerName: "Mobile",
      field: "mobile",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Pin",
      field: "pin",
      minWidth: 100,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    // {
    //   headerName: 'Balance',
    //   field: 'wallet',
    //   width: '120px',
    //   cellStyle: { 'text-align': 'center' },
    // },

    // {
    //   headerName: "KYC Status",
    //   field: "iskycCompleted",
    //   minWidth: 120,
    //   flex: 1,
    //   cellRenderer: (params) => (params.value ? "True" : "False"),
    //   cellStyle: (params) => ({
    //     color: params.value ? "green" : "red",
    //     textAlign: "center",
    //   }),
    // },
    // {
    //   headerName: "Reg Date",
    //   field: "regDate",
    //   minWidth: 150,
    //   flex: 1,
    //   sortable: true,
    //   sortingOrder: ["asc", "desc"],
    //   sort: "desc",
    //   cellRenderer: (params) => params.value || "-",
    // },
    {
      headerName: "Birthdate",
      field: "dob",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    // {
    //   headerName: "Parent User Name",
    //   field: "creator",
    //   minWidth: 250,
    //   flex: 1,
    //   sortable: true,
    //   sortingOrder: ["asc", "desc"],
    //   sort: "desc",
    //   cellRenderer: (params) => params?.username || "-",
    // },
    // {
    //   headerName: "Last Login",
    //   field: "lastLogin",
    //   minWidth: 150,
    //   flex: 1,
    //   sortable: true,
    //   sortingOrder: ["asc", "desc"],
    //   sort: "desc",
    //   cellRenderer: (params) => params.value?.replace("T", " ") || "-",
    // },
    // {
    //   headerName: "Last Login Ip Address",
    //   field: "lastLoginIP",
    //   minWidth: 250,
    //   flex: 1,
    //   cellRenderer: (params) => params.value?.replace("T", " ") || "-",
    // },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 250,
      field: "actionButton",
      cellRenderer: (item) => actionCellRenderer(item),
    },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  const handleDepositWithdraw = async (e) => {
    try {
      e.preventDefault();
      if (
        userDetail?.amount <= user?.balance ||
        user?.level === "master" ||
        userDetail?.type === "withdraw"
      ) {
        if (userDetail?.userId && userDetail?.type && userDetail?.amount) {
          setPaymentLoading(true);
          const res = await userPayment(userDetail);
          setPaymentLoading(false);
          const { status } = res || {};
          if (status !== 200) {
            return toast.error("Failed");
          }
          if (userDetail.type === "deposit") {
            setUser({ ...user, balance: user.balance - userDetail.amount });
          } else if (userDetail.type === "withdraw") {
            setUser({
              ...user,
              balance: parseInt(user.balance) + parseInt(userDetail.amount),
            });
          }
          setWithdrawShow(false);
          getAllPlayerData(page, pageSize);
          toast.success(
            `Your ${userDetail.type} request successfully completed`
          );
          setUserDetail({
            userId: "",
            amount: 0,
            type: "",
            currentAmount: 0,
          });
        } else {
          setPaymentLoading(false);
          return toast.error("Please enter the amount or please try again!");
        }
      } else {
        setPaymentLoading(false);
        return toast.error("Please Enter a valid amount");
      }
    } catch (err) {
      setPaymentLoading(false);
      return toast.error("Something went wrong!");
    }
  };

  const handleNewUser = async (values) => {
    try {
      setLoading(true);
      console.log(addUsers, selectedUserDetails);
      const value = user?.agentId?.toUpperCase();
      delete values.confirmPassword;
      if (addUsers === "edit") {
        values.id = selectedUserDetails?.id;
        delete values.userName;
      }
      const res =
        addUsers === "create"
          ? await createUser({ ...values, agentId: value })
          : await UpdateUser({ ...values });
      if (res.status === 201 || 200) {
        setLoading(false);
        toast.success(
          `${
            addUsers.charAt(0).toUpperCase() + addUsers.slice(1)
          } user successfully`
        );
        setAddUsers("");
        setSelectedUserDetails({});
        getAllPlayerData(page, pageSize);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    } catch (err) {
      return toast.error("Something went wrong!");
    }
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Users</h6>
        <div className="right-area">
          <button
            className="add-agent-button"
            onClick={() => setAddUsers("create")}
          >
            Create User
          </button>
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : "20");
            }}
          />

          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : (
        // : rowData?.length === 0 ? (
        //   <h4 className="text-center my-5">No Data Found</h4>
        // )
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            loading={loading}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
      {/* <DepositPopup
        show={showDeposit}
        handleClose={() => setShowDeposit(false)}
        handleFunction={handleDepositWithdraw}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        paymentLoading={paymentLoading}
      /> */}
      <WithdrawPopup
        show={withdrawShow}
        handleClose={() => setWithdrawShow(false)}
        handleFunction={handleDepositWithdraw}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        paymentLoading={paymentLoading}
      />
      <AddUsersPopUp
        loading={loading}
        show={addUsers}
        handleClose={() => setAddUsers(false)}
        handleNewUser={handleNewUser}
        // setNewAgentDetail={setNewAgentDetail}
        selectedUserDetails={selectedUserDetails}
      />
    </div>
  );
};
export default UserTable;
